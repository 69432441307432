import React from 'react';
import * as S from './MainContainer.styled';

const MainContainer = ({ children }) => {
  return (
    <S.Body>
      <S.Margin>
        <S.Main >
          {children}
        </S.Main>
      </S.Margin>
    </S.Body>
  );
}

export default MainContainer;
